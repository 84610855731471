import React, { useRef, useState } from 'react'
import Layout from '../../components/layout/Layout'
import banner from '../../../assets/bannerimg.png'
import Net from '../../../assets/svg/Net'
import Facebook from '../../../assets/squrfacebook.png'
import SquareMsg from '../../../assets/squareMsg.png'
import Plus from '../../../assets/squarePlus.png'
import Instgram from '../../../assets/squareInstgram.png'
import Tiktok from '../../../assets/squareTiktok.png'
import Twitter from '../../../assets/squareTwitter.png'
import Linkdin from '../../../assets/squarelinkdin.png'
import Youtub from '../../../assets/squareyoutub.png'
import { FaArrowRight } from "react-icons/fa6";
import { IoIosVideocam } from "react-icons/io";
import { Link, useNavigate } from 'react-router-dom'
import { NotificationManager } from 'react-notifications';
const VideoHome = () => {
    const navigate = useNavigate()

    const [userName, setUserName] = useState('')
    const [meetingId, setMeetingId] = useState()
    const joinHandler = () => {
        localStorage.removeItem("email")
        if (userName) {
            localStorage.setItem("userName", userName)
            localStorage.setItem("meetingId", meetingId)
            navigate('./loby')
        } else {
            NotificationManager.error('fields are requried')
        }
    }
    const targetDivRef = useRef(null);
    const targetDivRef2 = useRef(null);
    const targetDivRef3 = useRef(null);

    const handleScrollToDiv = () => {
        const targetDiv = targetDivRef.current;
        if (targetDiv) {

            targetDiv.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleScrollToDiv2 = () => {
        const targetDiv = targetDivRef2.current;
        if (targetDiv) {

            targetDiv.scrollIntoView({ behavior: 'smooth' });
            
        }
    };
    const handleScrollToDiv3 = () => {
        const targetDiv = targetDivRef3.current;
        if (targetDiv) {

            targetDiv.scrollIntoView({ behavior: 'smooth' });
         
        }
    };

    return (
        <Layout>
            <div className='h-auto  relative bg-white lg:-mt-24 -mt-10'>
                <div>

                    <video width="auto" height="auto" autoPlay="true" muted loop>
                        <source src={require('./video3.mp4')} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className=' lg:px-16  '>
                    <div className='absolute lg:top-44 top-16'>
                        <button className='bg-blue-700 p-1 text-white text-sm rounded-full px-3'>Introducing</button>
                        <div className='lg:text-[88px] text-[50px] text-center font-bold text-white'>Streamore</div>
                        <p className='lg:text-[32px] text-white'>A place where video call meets<br /> streaming.</p>
                        {/* <div className='lg:mt-5 w-full mt-16 flex flex-col space-y-5  '>
                            <input value={meetingId} onChange={(e) => setMeetingId(e.target.value)} className='text-[14px]  placeholder-white pl-5 focus: outline-none rounded-[80px] border border-white bg-[#8E93f3] lg:bg-opacity-20 lg:backdrop-blur-lg  drop-shadow-lg   text-white lg:w-[377.803px] w-full    h-[51.912px] shrink-0' type='text' placeholder='Join Host Name' />
                            <input value={userName} onChange={(e) => setUserName(e.target.value)} className='text-[14px]  placeholder-white pl-5 focus: outline-none rounded-[80px] border border-white bg-[#8E93f3] lg:bg-opacity-20 lg:backdrop-blur-lg  drop-shadow-lg   text-white lg:w-[377.803px] w-full    h-[51.912px] shrink-0' type='text' placeholder=' Name' />
                            <button onClick={joinHandler} className='text-[#2540D9] text-center text-[20px] font-bold lg:w-[377.803px] w-full mx-2 shadow-xl h-[61.209px] shrink-0 rounded-[80px] bg-gray-500 lg:bg-white'>Join Now</button>
                        </div> */}
                    </div>
                    <div className='absolute right-10 lg:top-80 top-24 flex flex-col space-y-4'>
                        <button onClick={handleScrollToDiv} className='w-3 h-3 bg-white rounded-full'></button>
                        <button onClick={handleScrollToDiv2} className='w-3 h-3 bg-gray-200 rounded-full opacity-50'></button>
                        <button onClick={handleScrollToDiv3} className='w-3 h-3 bg-gray-200 rounded-full opacity-50'></button>
                    </div>
                </div>
                <div className='relative lg:h-[1185px] w-auto' ref={targetDivRef}>
                    <div className='overflow-x-hidden '> <Net /></div>
                    <div className=' ' >
                        <div className='absolute lg:top-28 lg:left-16'>
                            <p className='text-[40px] lg:w-[427px] h-[92.223px]'>Why stream with <span className='text-[48px] font-semibold'>Streamore</span></p>
                            <p className='lg:w-[473px] h-[188.502px] shrink-0 text-[sm] text-justify mt-14 text-[#606060]'>Streaming platform content for websites has witnessed a remarkable evolution in recent years, with the emergence of a captivating and innovative concept: the fusion of streaming and video podcasting. This unique approach combines the best elements of both worlds to provide a dynamic and engaging multimedia experience for audiences of all backgrounds and interests.</p>
                        </div>
                        <div  className='absolute lg:top-28 lg:mt-0 mt-80 lg:right-16'>
                            <img  className='shrink-0' src={banner} alt='banner' />
                        </div>
                        <div  className='absolute lg:top-[650px] top-[300px] mx-auto lg:ml-52 ml-0 '>
                            <div className='lg:flex lg:space-x-10 space-y-5 lg:space-y-0 px-3  '>
                                <div  className='lg:w-[310px] w-full h-[113px] gap-[16px] flex-shrink-0 rounded-[32px] bg-[#2540D9] backdrop-blur-4'>
                                    <div   className='flex justify-center items-center space-x-2 p-4'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="55" viewBox="0 0 68 55" fill="none">
                                                <path d="M61.1677 0.0996094H6.97197C3.63946 0.0996094 0.942383 2.79668 0.942383 6.1292V15.1524H6.97197V6.1292H61.1677V48.2939H40.0994V54.3235H61.1959C64.5284 54.3235 67.2255 51.6265 67.2255 48.2939V6.1292C67.2255 2.79668 64.5002 0.0996094 61.1677 0.0996094ZM0.942383 45.2297V54.2529H9.96558C9.96558 49.2683 5.92703 45.2297 0.942383 45.2297ZM0.942383 33.227V39.2143C9.25954 39.2143 15.9952 46.0064 15.9952 54.3235H22.0248C22.0389 42.688 12.5779 33.2412 0.942383 33.227ZM28.0685 54.3235H34.0981C34.0275 36.0371 19.2288 21.2385 0.942383 21.1961V27.1833C15.9104 27.1551 28.0543 39.3555 28.0685 54.3235Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div>
                                            <p className='text-[20px] font-semibold text-white'>Stream Anywhere</p>
                                            <p className='text-xs text-white text-justify'>With Streamore, you get the freedom to stream on multiple platforms.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:w-[310px] w-full h-[113px] lg:gap-[16px] flex-shrink-0 rounded-[32px] bg-[#2540D9] backdrop-blur-4'>
                                    <div className='flex justify-center items-center space-x-2 p-4'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="55" viewBox="0 0 68 55" fill="none">
                                                <path d="M61.1677 0.0996094H6.97197C3.63946 0.0996094 0.942383 2.79668 0.942383 6.1292V15.1524H6.97197V6.1292H61.1677V48.2939H40.0994V54.3235H61.1959C64.5284 54.3235 67.2255 51.6265 67.2255 48.2939V6.1292C67.2255 2.79668 64.5002 0.0996094 61.1677 0.0996094ZM0.942383 45.2297V54.2529H9.96558C9.96558 49.2683 5.92703 45.2297 0.942383 45.2297ZM0.942383 33.227V39.2143C9.25954 39.2143 15.9952 46.0064 15.9952 54.3235H22.0248C22.0389 42.688 12.5779 33.2412 0.942383 33.227ZM28.0685 54.3235H34.0981C34.0275 36.0371 19.2288 21.2385 0.942383 21.1961V27.1833C15.9104 27.1551 28.0543 39.3555 28.0685 54.3235Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div>
                                            <p className='text-[20px] font-semibold text-white'>Stream Anywhere</p>
                                            <p className='text-xs text-white text-justify'>With Streamore, you get the freedom to stream on multiple platforms.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:w-[310px] w-full h-[113px] gap-[16px] flex-shrink-0 rounded-[32px] bg-[#2540D9] backdrop-blur-4'>
                                    <div className='flex justify-center items-center space-x-2 p-4'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="55" viewBox="0 0 68 55" fill="none">
                                                <path d="M61.1677 0.0996094H6.97197C3.63946 0.0996094 0.942383 2.79668 0.942383 6.1292V15.1524H6.97197V6.1292H61.1677V48.2939H40.0994V54.3235H61.1959C64.5284 54.3235 67.2255 51.6265 67.2255 48.2939V6.1292C67.2255 2.79668 64.5002 0.0996094 61.1677 0.0996094ZM0.942383 45.2297V54.2529H9.96558C9.96558 49.2683 5.92703 45.2297 0.942383 45.2297ZM0.942383 33.227V39.2143C9.25954 39.2143 15.9952 46.0064 15.9952 54.3235H22.0248C22.0389 42.688 12.5779 33.2412 0.942383 33.227ZM28.0685 54.3235H34.0981C34.0275 36.0371 19.2288 21.2385 0.942383 21.1961V27.1833C15.9104 27.1551 28.0543 39.3555 28.0685 54.3235Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div>
                                            <p className='text-[20px] font-semibold text-white'>Stream Anywhere</p>
                                            <p className='text-xs text-white text-justify'>With Streamore, you get the freedom to stream on multiple platforms.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center lg:w-full lg:h-[94.554px] z-20 text-[#787EF1] font-extrabold lg:text-[90px] text-[20px] -mt-80  ml-0'>
                    Broadcast Live Anywhere.
                </div>
                <div className='lg:mr-10 lg:mt-14'>
                    <div className='flex justify-end lg:mr-72 '>
                        <img src={Facebook} alt='facebook' />
                        <img src={SquareMsg} alt='SquareMsg' />
                        <img src={Plus} alt='Plus' />
                        <img src={Instgram} alt='Instgram' />
                    </div>
                    <div className='flex lg:justify-end lg:mr-24  '>
                        <img src={Tiktok} alt='Tiktok' />
                        <img src={Twitter} alt='Twitter' />
                        <img src={Linkdin} alt='Linkdin' />
                        <img src={Youtub} alt='Youtub' />
                    </div>
                </div>
                <div className='relative lg:-mt-80 mt-0'>
                    <svg className='w-full lg-mt-32  mt-[425px] ' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 738" fill="none">
                        <path d="M0.000119871 737.19L1440 737.19L1440 75.8819C1440 75.8819 1083.2 311.088 629.212 110.04C175.222 -91.0072 6.03647e-05 46.6986 6.03647e-05 46.6986L0.000119871 737.19Z" fill="#787EF1" />
                    </svg>
                    <div className=' w-full lg:text-[40px] text-white flex justify-center absolute lg:top-48  font-thin'>
                        <div className='text-center'>
                            <div className=''>Subscribe now!</div>
                            <div ref={targetDivRef2} className='font-extrabold'>Plan and Pricing</div>

                        </div>
                    </div>
                    <body   class="font-sans bg-[#787EF1] pb-20 lg:-mt-[410px] mt-0 ">
                        <div class="h-auto lg:flex lg:justify-center lg:items-center px-3">
                            <div class="">
                                <div class="text-center font-semibold">


                                </div>
                                <div class="pt-24 lg:flex  flex-row">

                                    <div class="lg:w-96 w-full p-8 bg-white text-center rounded-3xl pr-16 shadow-xl ">
                                        <h1 class="text-black font-semibold text-2xl">Basic</h1>
                                        <p class="pt-2 tracking-wide">
                                            <span class="text-gray-400 align-top">$ </span>
                                            <span class="text-3xl font-semibold">10</span>
                                            <span class="text-gray-400 font-medium">/ user</span>
                                        </p>
                                        <hr class="mt-4 border-1" />
                                        <div class="pt-8">
                                            <p class="font-semibold text-gray-400 text-left">
                                                <span class="material-icons align-middle">
                                                    done
                                                </span>
                                                <span class="pl-2">
                                                    Get started with <span class="text-black">messaging</span>
                                                </span>
                                            </p>
                                            <p class="font-semibold text-gray-400 text-left pt-5">
                                                <span class="material-icons align-middle">
                                                    done
                                                </span>
                                                <span class="pl-2">
                                                    Flexible <span class="text-black">team meetings</span>
                                                </span>
                                            </p>
                                            <p class="font-semibold text-gray-400 text-left pt-5">
                                                <span class="material-icons align-middle">
                                                    done
                                                </span>
                                                <span class="pl-2">
                                                    <span class="text-black">5 TB</span> cloud storage
                                                </span>
                                            </p>

                                            <Link to={"/paymentSuccessful"}>
                                                <p class="w-full py-4 bg-blue-600 mt-8 rounded-xl text-white">
                                                    <span class="font-medium">
                                                        Choose Plan
                                                    </span>
                                                    <span class="pl-2 material-icons align-middle text-sm">
                                                        east
                                                    </span>
                                                </p>
                                            </Link>
                                        </div>
                                    </div>

                                    <div class="lg:w-80 w-72 mx-auto my-5 p-8 bg-gray-900 text-center rounded-3xl text-white border-4 shadow-xl border-white transform scale-125">
                                        <h1 class="text-white font-semibold text-2xl">Startup</h1>
                                        <p class="pt-2 tracking-wide">
                                            <span class="text-gray-400 align-top">$ </span>
                                            <span class="text-3xl font-semibold">24</span>
                                            <span class="text-gray-400 font-medium">/ user</span>
                                        </p>
                                        <hr class="mt-4 border-1 border-gray-600" />
                                        <div class="pt-8">
                                            <p class="font-semibold text-gray-400 text-left">
                                                <span class="material-icons align-middle">
                                                    done
                                                </span>
                                                <span class="pl-2">
                                                    All features in <span class="text-white">Basic</span>
                                                </span>
                                            </p>
                                            <p class="font-semibold text-gray-400 text-left pt-5">
                                                <span class="material-icons align-middle">
                                                    done
                                                </span>
                                                <span class="pl-2">
                                                    Flexible <span class="text-white">call scheduling</span>
                                                </span>
                                            </p>
                                            <p class="font-semibold text-gray-400 text-left pt-5">
                                                <span class="material-icons align-middle">
                                                    done
                                                </span>
                                                <span class="pl-2">
                                                    <span class="text-white">15 TB</span> cloud storage
                                                </span>
                                            </p>

                                            <div  class="">
                                               <Link to={"/paymentSuccessful"}>
                                               <p class="w-full py-4 bg-blue-600 mt-8 rounded-xl text-white">
                                               <span class="font-medium">
                                                   Choose Plan
                                               </span>
                                               <span class="pl-2 material-icons align-middle text-sm">
                                                   east
                                               </span>
                                           </p>
                                               </Link>
                                            </div>
                                        </div>
                                        <div class="absolute top-4 right-4">
                                            <p class="bg-blue-700 font-semibold px-4 py-1 rounded-full uppercase text-xs">Popular</p>
                                        </div>
                                    </div>

                                    <div class="lg:w-96 w-full p-8 bg-white text-center rounded-3xl pl-16 shadow-xl">
                                        <h1 class="text-black font-semibold text-2xl">Enterprise</h1>
                                        <p class="pt-2 tracking-wide">
                                            <span class="text-gray-400 align-top">$ </span>
                                            <span class="text-3xl font-semibold">35</span>
                                            <span class="text-gray-400 font-medium">/ user</span>
                                        </p>
                                        <hr class="mt-4 border-1" />
                                        <div class="pt-8">
                                            <p class="font-semibold text-gray-400 text-left">
                                                <span class="material-icons align-middle">
                                                    done
                                                </span>
                                                <span class="pl-2">
                                                    All features in <span class="text-black">Startup</span>
                                                </span>
                                            </p>
                                            <p class="font-semibold text-gray-400 text-left pt-5">
                                                <span class="material-icons align-middle">
                                                    done
                                                </span>
                                                <span class="pl-2">
                                                    Growth <span class="text-black">oriented</span>
                                                </span>
                                            </p>
                                            <p class="font-semibold text-gray-400 text-left pt-5">
                                                <span class="material-icons align-middle">
                                                    done
                                                </span>
                                                <span class="pl-2">
                                                    <span class="text-black">Unlimited</span> cloud storage
                                                </span>
                                            </p>

                                            <Link to={"/paymentSuccessful"}>
                                                <p class="w-full py-4 bg-blue-600 mt-8 rounded-xl text-white">
                                                    <span class="font-medium">
                                                        Choose Plan
                                                    </span>
                                                    <span class="pl-2 material-icons align-middle text-sm">
                                                        east
                                                    </span>
                                                </p>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </body>
                </div>
                <div ref={targetDivRef3} className='bg-[#787EF1] text-center flex justify-center space-x-4'>
                    <p className='text-[24px] text-white'>Compare all features </p>
                    <p>  <FaArrowRight className='text-[24px] text-white mt-2.5' /></p>
                </div>
                <div>
                    <div className='lg:flex lg:justify-around bg-[#787EF1]'>
                        <div className='flex  items-center  justify-center '>
                            <img src={Facebook} alt='facebook' />
                            <img src={SquareMsg} alt='SquareMsg' />
                            <img src={Plus} alt='Plus' />
                        </div>
                        <div>
                            <p className='lg:text-[56px] text-center text-white font-thin'>What are you waiting for?</p>
                            <p className='lg:text-[56px] text-center text-white'>Start Streaming Now!</p>
                        </div>
                        <div className='flex   items-center  justify-center '>
                            <img src={Tiktok} alt='Tiktok' />
                            <img src={Twitter} alt='Twitter' />
                            <img src={Instgram} alt='Instgram' />

                        </div>
                    </div>
                    <div className='bg-[#787EF1] flex justify-center py-16'>
                        <button className='lg:w-[473px] w-full mx-3 h-[67.997px] flex justify-center items-center  rounded-[80px] bg-white text-[#2540D9] text-[20px] font-bold'> <IoIosVideocam className='text-[30px] lg:mr-2' />Start or Join Stream</button>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default VideoHome
