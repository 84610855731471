import React from 'react'
import logo from '../../../assets/f-logo.png'
import facebook from '../../../assets/facebook-logo.png'
import twitter from '../../../assets/twitter-logo.png'
import instgram from '../../../assets/instagram-logo.png'
function Footer() {
  return (
    <div className='h-[276px] bg-[#000]'>
      <div className='grid grid-cols-5 text-white p-5 '>
        <div className='p-2'>
          <img className='w-12' src={logo} alt='logo' />
          <p className='text-xs my-3'>This is a short company’s description. It should be only 1 to 3 lines.</p>
          <p className='text-xs text-justify'><span className='mr-2'>@2023</span>Name of the company</p>
        </div>
        <div className='p-2 text-justify'>
          <p className="text-[14px] font-bold">Quick links</p>
          <p className='text-xs mt-3'>About us</p>
          <p className='text-xs my-3'>Services</p>
          <p className='text-xs my-3'>Blog</p>
          <p className='text-xs my-3'>Contact us</p>
          
        </div>
        <div className='p-2 text-justify'>
          <p className="text-[14px] font-bold">Product</p>
          <p className='text-xs mt-3'>Pricing</p>
          <p className='text-xs my-3'>Features</p>
          <p className='text-xs my-3'>Customers</p>          
        </div>
        <div className='p-2 text-justify'>
          <p className="text-[14px] font-bold">Help</p>
          <p className='text-xs mt-3'>Getting started</p>
          <p className='text-xs my-3'>FAQs</p>
        </div>
        <div className='flex space-x-5 mt-5'>
          <img className='h-[32px] w-[32px]' src={facebook} alt='facebook'/>
          <img className='h-[32px] w-[32px]' src={twitter} alt='twitter'/>
          {/* <img className='h-[32px] w-[32px]' src={instgram} alt='instgram'/> */}
        </div>
      </div>
    </div>
  )
}

export default Footer
