import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import { IoChatbubbleSharp, IoStopSharp } from "react-icons/io5";
import { IoIosMic, IoIosMicOff } from "react-icons/io";
import { FaVideo, FaVideoSlash } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
function Meeting() {
    const [mute, setMute] = useState(true)
    const [videoStatus, setVideoStatus] = useState(true)
    return (
        <>
            <div className='h-screen p-12 '>
                <div className='flex space-x-7'>
                    <div className=' flex '>
                        <div className='bg-gray-300 w-[474px] h-[413.838px] shadow-xl rounded-md  mb-5 m-5'>
                            <video class="primary-video" autoplay muted id="local-video">
                                
                            </video>
                        </div>
                        <div >
                            <div className=' bg-gray-300  w-[474px] h-[197.879px] shadow-xl rounded-md  mt-5'>
                                <video class="secondary-video" autoplay id="remote-video"></video>
                            </div>
                            <div className=' bg-gray-300  w-[474px] h-[197.879px] shadow-xl rounded-md mt-5'>
                                <video class="secondary-video" autoplay id="remote-video"></video>
                            </div>

                        </div>
                    </div>
                    <div className=' shadow-lg rounded-sm bg-gray-100 mt-5 w-full'>
                        <div className='border-b text-center border-gray-400'>
                            <p className='text-[32px] font-bold text-center  '>Live chats</p>
                        </div>

                    </div>

                </div>
                <div className='flex justify-center space-x-5'>
                    <div className='bg-[#2540D9] h-[46.913px] w-[46.913px] rounded-full flex shadow-xl cursor-pointer justify-center items-center'>
                        <IoChatbubbleSharp className='text-white text-[25px]' />
                    </div>
                    <div className='bg-[#2540D9] h-[46.913px] w-[46.913px] rounded-full flex shadow-xl cursor-pointer justify-center items-center'>
                        {
                            mute ? <IoIosMic onClick={() => setMute(!mute)} className='text-white text-[25px]' /> : <IoIosMicOff onClick={() => setMute(!mute)} className='text-white text-[25px]' />
                        }
                    </div>
                    <div className='bg-[#c93a2a] h-[46.913px] w-[46.913px] rounded-full flex shadow-xl cursor-pointer justify-center items-center'>
                        <IoStopSharp className='text-white text-[25px]' />
                    </div>
                    <div className='bg-[#2540D9] h-[46.913px] w-[46.913px] rounded-full flex shadow-xl cursor-pointer justify-center items-center'>
                        {
                            videoStatus ? <FaVideo onClick={() => setVideoStatus(!videoStatus)} className='text-white text-[25px]' /> : <FaVideoSlash onClick={() => setVideoStatus(!videoStatus)} className='text-white text-[25px]' />
                        }
                    </div>
                    <div className='bg-[#CCD3FA] h-[46.913px] w-[46.913px] rounded-full flex shadow-xl cursor-pointer justify-center items-center'>
                        <BsThreeDotsVertical className='text-white text-[25px]' />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Meeting
