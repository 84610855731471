import React from "react";
import Layout from "../../components/layout/Layout";
import paymentBackground from "../../../assets/backgroundPhotoWithHeadphone.png";
import { Link } from "react-router-dom";
function PaymentSuccessful() {
  return (
    <Layout>
      <div className="h-screen relative bg-[#6964BA] flex justify-center -mt-20">
        <img
          className="w-[100%] h-[100%] opacity-100 "
          src={paymentBackground}
        />
        
      </div>
      <div className="absolute top-[20%] left-[40%] flex flex-col text-center items-center">
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="153"
      height="152"
      viewBox="0 0 153 152"
      fill="none"
    >
      <rect
        x="0.382507"
        width="151.98"
        height="151.98"
        rx="32"
        fill="white"
      />
      <rect
        x="30.4182"
        y="35.9453"
        width="16.4742"
        height="16.4742"
        rx="8.23712"
        fill="#787EF1"
      />
      <rect
        x="101.675"
        y="35.9453"
        width="16.4742"
        height="16.4742"
        rx="8.23712"
        fill="#787EF1"
      />
      <path
        d="M116.96 78.7733C119.791 78.2274 122.56 80.0811 122.785 82.9552C123.184 88.0324 122.748 93.1529 121.483 98.1105C119.787 104.758 116.642 110.949 112.274 116.24C107.906 121.531 102.422 125.791 96.2157 128.715C90.0091 131.64 83.2329 133.156 76.3719 133.156C69.511 133.156 62.7348 131.639 56.5283 128.715C50.3218 125.79 44.8384 121.53 40.4705 116.239C36.1026 110.948 32.958 104.757 31.2619 98.1093C29.9971 93.1516 29.5611 88.0312 29.96 82.9539C30.1858 80.0798 32.9542 78.2263 35.785 78.7722C38.6158 79.3182 40.4277 82.06 40.2952 84.9399C40.1316 88.494 40.4937 92.0625 41.378 95.5284C42.6937 100.686 45.1331 105.488 48.5215 109.593C51.9099 113.697 56.1637 117.002 60.9784 119.271C65.7931 121.539 71.0497 122.716 76.3721 122.716C81.6945 122.716 86.9511 121.54 91.7659 119.271C96.5806 117.003 100.834 113.698 104.223 109.593C107.612 105.489 110.051 100.687 111.367 95.5293C112.251 92.0634 112.613 88.495 112.45 84.9409C112.318 82.061 114.13 79.3192 116.96 78.7733Z"
        fill="#787EF1"
      />
    </svg>
    <h className="text-[32px] text-white font-bold mt-5">PAYMENT SUCCESSFUL.</h>
    <p className="text-[32px] text-white mt-5">Happy streaming!</p>
  <Link to="/loby" ><button className="text-[#2540D9] w-[441.255px] h-[61.209px] bg-white rounded-[80px] mt-10">Continue to Streamore</button></Link>
    
        </div>
    </Layout>
  );
}

export default PaymentSuccessful;
