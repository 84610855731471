import React from 'react'
import Layout from '../../components/layout/Layout'
import banner from '../../../assets/bannerimg.png'
import Net from '../../../assets/svg/Net'
import Facebook from '../../../assets/squrfacebook.png'
import SquareMsg from '../../../assets/squareMsg.png'
import Plus from '../../../assets/squarePlus.png'
import Instgram from '../../../assets/squareInstgram.png'
import Tiktok from '../../../assets/squareTiktok.png'
import Twitter from '../../../assets/squareTwitter.png'
import Linkdin from '../../../assets/squarelinkdin.png'
import Youtub from '../../../assets/squareyoutub.png'
import { FaArrowRight } from "react-icons/fa6";
import { IoIosVideocam } from "react-icons/io";

function Home() {
    return (
        <Layout>
            <div className='h-auto  relative bg-white '>
                <div>
                    <svg className='-mt-24 h-[720px] w-full' width="1440" height="621" viewBox="0 0 1440 621" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 -99.6902p440V532.74C1440 532.74 1071.38 335.281 629.212 532.74C187.046 730.2 0 532.74 0 532.74V-99.6902Z" fill="#787EF1" />
                    </svg>
                </div>
                <div className=' px-16 '>
                    <div className='absolute top-32'>
                        <button className='bg-black p-1 text-white text-sm rounded-full px-3'>Introducing</button>
                        <div className='text-[88px] font-bold text-white'>Streamore</div>
                        <p className='text-[32px] text-white'>A place where video call meets<br /> streaming.</p>
                        <div className='mt-5 flex flex-col space-y-5'>
                            <input className='text-[14px] placeholder-white pl-5 focus: outline-none rounded-[80px] border border-white bg-[#8E93f3] text-white w-[377.803px] h-[51.912px] shrink-0' type='text' placeholder='Create a new Username' />
                            <input className='text-[14px] placeholder-white pl-5 focus: outline-none rounded-[80px] border border-white bg-[#8E93f3] text-white w-[377.803px] h-[51.912px] shrink-0' type='text' placeholder='Set Password (Must have minimum of 8 digits)' />
                            <button className='text-[#2540D9] text-center text-[20px] font-bold w-[377.803px] h-[61.209px] shrink-0 rounded-[80px] bg-white'>Signup Now</button>
                        </div>
                    </div>

                    <div className=' absolute top-[160px] right-16'>
                        <img className='shrink-0' src={banner} alt='banner' />
                    </div>
                    <div className='absolute right-10 top-80 flex flex-col space-y-4'>
                        <button className='w-3 h-3 bg-white rounded-full'></button>
                        <button className='w-3 h-3 bg-gray-200 rounded-full opacity-50'></button>
                        <button className='w-3 h-3 bg-gray-200 rounded-full opacity-50'></button>
                    </div>
                </div>
                <div className='relative h-[1185px]' >
                    <Net />
                    <div className=' '>
                        <div className='absolute top-28 left-16'>
                            <p className='text-[40px] w-[427px] h-[92.223px]'>Why stream with <span className='text-[48px] font-semibold'>Streamore</span></p>
                            <p className='w-[473px] h-[188.502px] shrink-0 text-[sm] text-justify mt-14 text-[#606060]'>Streaming platform content for websites has witnessed a remarkable evolution in recent years, with the emergence of a captivating and innovative concept: the fusion of streaming and video podcasting. This unique approach combines the best elements of both worlds to provide a dynamic and engaging multimedia experience for audiences of all backgrounds and interests.</p>
                        </div>
                        <div className='absolute top-28 right-16'>
                            <div className='w-[587px] h-[356px] bg-[#495057] rounded flex justify-center items-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M39.0708 24.0121C39.7182 24.4005 39.7182 25.3387 39.0708 25.7271L12.0145 41.9609C11.348 42.3608 10.5 41.8807 10.5 41.1034V8.63582C10.5 7.85853 11.348 7.37841 12.0145 7.77833L39.0708 24.0121Z" stroke="white" strokeWidth="4.66667" stroke-linejoin="round" />
                                </svg>
                            </div>
                        </div>
                        <div className='absolute top-[650px]  '>
                            <div className='flex space-x-10  mx-40'>
                                <div className='w-[310px] h-[113px] gap-[16px] flex-shrink-0 rounded-[32px] bg-[#2540D9] backdrop-blur-4'>
                                    <div className='flex justify-center items-center space-x-2 p-4'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="55" viewBox="0 0 68 55" fill="none">
                                                <path d="M61.1677 0.0996094H6.97197C3.63946 0.0996094 0.942383 2.79668 0.942383 6.1292V15.1524H6.97197V6.1292H61.1677V48.2939H40.0994V54.3235H61.1959C64.5284 54.3235 67.2255 51.6265 67.2255 48.2939V6.1292C67.2255 2.79668 64.5002 0.0996094 61.1677 0.0996094ZM0.942383 45.2297V54.2529H9.96558C9.96558 49.2683 5.92703 45.2297 0.942383 45.2297ZM0.942383 33.227V39.2143C9.25954 39.2143 15.9952 46.0064 15.9952 54.3235H22.0248C22.0389 42.688 12.5779 33.2412 0.942383 33.227ZM28.0685 54.3235H34.0981C34.0275 36.0371 19.2288 21.2385 0.942383 21.1961V27.1833C15.9104 27.1551 28.0543 39.3555 28.0685 54.3235Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div>
                                            <p className='text-[20px] font-semibold text-white'>Stream Anywhere</p>
                                            <p className='text-xs text-white text-justify'>With Streamore, you get the freedom to stream on multiple platforms.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-[310px] h-[113px] gap-[16px] flex-shrink-0 rounded-[32px] bg-[#2540D9] backdrop-blur-4'>
                                    <div className='flex justify-center items-center space-x-2 p-4'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="55" viewBox="0 0 68 55" fill="none">
                                                <path d="M61.1677 0.0996094H6.97197C3.63946 0.0996094 0.942383 2.79668 0.942383 6.1292V15.1524H6.97197V6.1292H61.1677V48.2939H40.0994V54.3235H61.1959C64.5284 54.3235 67.2255 51.6265 67.2255 48.2939V6.1292C67.2255 2.79668 64.5002 0.0996094 61.1677 0.0996094ZM0.942383 45.2297V54.2529H9.96558C9.96558 49.2683 5.92703 45.2297 0.942383 45.2297ZM0.942383 33.227V39.2143C9.25954 39.2143 15.9952 46.0064 15.9952 54.3235H22.0248C22.0389 42.688 12.5779 33.2412 0.942383 33.227ZM28.0685 54.3235H34.0981C34.0275 36.0371 19.2288 21.2385 0.942383 21.1961V27.1833C15.9104 27.1551 28.0543 39.3555 28.0685 54.3235Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div>
                                            <p className='text-[20px] font-semibold text-white'>Stream Anywhere</p>
                                            <p className='text-xs text-white text-justify'>With Streamore, you get the freedom to stream on multiple platforms.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-[310px] h-[113px] gap-[16px] flex-shrink-0 rounded-[32px] bg-[#2540D9] backdrop-blur-4'>
                                    <div className='flex justify-center items-center space-x-2 p-4'>
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="68" height="55" viewBox="0 0 68 55" fill="none">
                                                <path d="M61.1677 0.0996094H6.97197C3.63946 0.0996094 0.942383 2.79668 0.942383 6.1292V15.1524H6.97197V6.1292H61.1677V48.2939H40.0994V54.3235H61.1959C64.5284 54.3235 67.2255 51.6265 67.2255 48.2939V6.1292C67.2255 2.79668 64.5002 0.0996094 61.1677 0.0996094ZM0.942383 45.2297V54.2529H9.96558C9.96558 49.2683 5.92703 45.2297 0.942383 45.2297ZM0.942383 33.227V39.2143C9.25954 39.2143 15.9952 46.0064 15.9952 54.3235H22.0248C22.0389 42.688 12.5779 33.2412 0.942383 33.227ZM28.0685 54.3235H34.0981C34.0275 36.0371 19.2288 21.2385 0.942383 21.1961V27.1833C15.9104 27.1551 28.0543 39.3555 28.0685 54.3235Z" fill="white" />
                                            </svg>
                                        </div>
                                        <div>
                                            <p className='text-[20px] font-semibold text-white'>Stream Anywhere</p>
                                            <p className='text-xs text-white text-justify'>With Streamore, you get the freedom to stream on multiple platforms.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex justify-center items-center w-[1321.634px] h-[94.554px] z-20 text-[#787EF1] font-extrabold text-[102px] -mt-80'>
                    Broadcast Live Anywhere.
                </div>
                <div className='mr-10 mt-14'>
                    <div className='flex justify-end mr-72 '>
                        <img src={Facebook} alt='facebook' />
                        <img src={SquareMsg} alt='SquareMsg' />
                        <img src={Plus} alt='Plus' />
                        <img src={Instgram} alt='Instgram' />
                    </div>
                    <div className='flex justify-end mr-24  '>
                        <img src={Tiktok} alt='Tiktok' />
                        <img src={Twitter} alt='Twitter' />
                        <img src={Linkdin} alt='Linkdin' />
                        <img src={Youtub} alt='Youtub' />
                    </div>
                </div>
                <div className='relative'>
                    <svg className='w-full -mt-32  ' xmlns="http://www.w3.org/2000/svg" width="1440" height="738" viewBox="0 0 1440 738" fill="none">
                        <path d="M0.000119871 737.19L1440 737.19L1440 75.8819C1440 75.8819 1083.2 311.088 629.212 110.04C175.222 -91.0072 6.03647e-05 46.6986 6.03647e-05 46.6986L0.000119871 737.19Z" fill="#787EF1" />
                    </svg>
                    <div className=' w-full text-[40px] text-white flex justify-center absolute top-48  font-thin'>
                        <div className='text-center'>
                            <div className=''>Subscribe now!</div>
                            <div className='font-extrabold'>Plan and Pricing</div>

                        </div>
                    </div>
                    <body className="font-sans bg-[#787EF1] pb-20 -mt-80 ">
                        <div className="h-auto flex justify-center items-center">
                            <div className="">
                                <div className="text-center font-semibold">


                                </div>
                                <div className="pt-24 flex flex-row">

                                    <div className="w-96 p-8 bg-white text-center rounded-3xl pr-16 shadow-xl">
                                        <p className="text-black font-semibold text-2xl">Basic</p>
                                        <p className="pt-2 tracking-wide">
                                            <span className="text-gray-400 align-top">$ </span>
                                            <span className="text-3xl font-semibold">10</span>
                                            <span className="text-gray-400 font-medium">/ user</span>
                                        </p>
                                        <hr className="mt-4 border-1" />
                                        <div className="pt-8">
                                            <p className="font-semibold text-gray-400 text-left">
                                                <span className="material-icons align-middle">
                                                    done
                                                </span>
                                                <span className="pl-2">
                                                    Get started with <span className="text-black">messaging</span>
                                                </span>
                                            </p>
                                            <p className="font-semibold text-gray-400 text-left pt-5">
                                                <span className="material-icons align-middle">
                                                    done
                                                </span>
                                                <span className="pl-2">
                                                    Flexible <span className="text-black">team meetings</span>
                                                </span>
                                            </p>
                                            <p className="font-semibold text-gray-400 text-left pt-5">
                                                <span className="material-icons align-middle">
                                                    done
                                                </span>
                                                <span className="pl-2">
                                                    <span className="text-black">5 TB</span> cloud storage
                                                </span>
                                            </p>

                                            <a href="#" className="">
                                                <p className="w-full py-4 bg-blue-600 mt-8 rounded-xl text-white">
                                                    <span className="font-medium">
                                                        Choose Plan
                                                    </span>
                                                    <span className="pl-2 material-icons align-middle text-sm">
                                                        east
                                                    </span>
                                                </p>
                                            </a>
                                        </div>
                                    </div>

                                    <div className="w-80 p-8 bg-gray-900 text-center rounded-3xl text-white border-4 shadow-xl border-white transform scale-125">
                                        <p className="text-white font-semibold text-2xl">Startup</p>
                                        <p className="pt-2 tracking-wide">
                                            <span className="text-gray-400 align-top">$ </span>
                                            <span className="text-3xl font-semibold">24</span>
                                            <span className="text-gray-400 font-medium">/ user</span>
                                        </p>
                                        <hr className="mt-4 border-1 border-gray-600" />
                                        <div className="pt-8">
                                            <p className="font-semibold text-gray-400 text-left">
                                                <span className="material-icons align-middle">
                                                    done
                                                </span>
                                                <span className="pl-2">
                                                    All features in <span className="text-white">Basic</span>
                                                </span>
                                            </p>
                                            <p className="font-semibold text-gray-400 text-left pt-5">
                                                <span className="material-icons align-middle">
                                                    done
                                                </span>
                                                <span className="pl-2">
                                                    Flexible <span className="text-white">call scheduling</span>
                                                </span>
                                            </p>
                                            <p className="font-semibold text-gray-400 text-left pt-5">
                                                <span className="material-icons align-middle">
                                                    done
                                                </span>
                                                <span className="pl-2">
                                                    <span className="text-white">15 TB</span> cloud storage
                                                </span>
                                            </p>

                                            <a href="#" className="">
                                                <p className="w-full py-4 bg-blue-600 mt-8 rounded-xl text-white">
                                                    <span className="font-medium">
                                                        Choose Plan
                                                    </span>
                                                    <span className="pl-2 material-icons align-middle text-sm">
                                                        east
                                                    </span>
                                                </p>
                                            </a>
                                        </div>
                                        <div className="absolute top-4 right-4">
                                            <p className="bg-blue-700 font-semibold px-4 py-1 rounded-full uppercase text-xs">Popular</p>
                                        </div>
                                    </div>

                                    <div className="w-96 p-8 bg-white text-center rounded-3xl pl-16 shadow-xl">
                                        <p className="text-black font-semibold text-2xl">Enterprise</p>
                                        <p className="pt-2 tracking-wide">
                                            <span className="text-gray-400 align-top">$ </span>
                                            <span className="text-3xl font-semibold">35</span>
                                            <span class="text-gray-400 font-medium">/ user</span>
                                        </p>
                                        <hr className="mt-4 border-1" />
                                        <div className="pt-8">
                                            <p className="font-semibold text-gray-400 text-left">
                                                <span className="material-icons align-middle">
                                                    done
                                                </span>
                                                <span className="pl-2">
                                                    All features in <span class="text-black">Startup</span>
                                                </span>
                                            </p>
                                            <p className="font-semibold text-gray-400 text-left pt-5">
                                                <span className="material-icons align-middle">
                                                    done
                                                </span>
                                                <span className="pl-2">
                                                    Growth <span className="text-black">oriented</span>
                                                </span>
                                            </p>
                                            <p className="font-semibold text-gray-400 text-left pt-5">
                                                <span className="material-icons align-middle">
                                                    done
                                                </span>
                                                <span className="pl-2">
                                                    <span className="text-black">Unlimited</span> cloud storage
                                                </span>
                                            </p>

                                            <a href="#" className="">
                                                <p className="w-full py-4 bg-blue-600 mt-8 rounded-xl text-white">
                                                    <span className="font-medium">
                                                        Choose Plan
                                                    </span>
                                                    <span className="pl-2 material-icons align-middle text-sm">
                                                        east
                                                    </span>
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </body>
                </div>
                <div className='bg-[#787EF1] text-center flex justify-center space-x-4'>
                    <p className='text-[24px] text-white'>Compare all features </p>
                    <p>  <FaArrowRight className='text-[24px] text-white mt-2.5' /></p>
                </div>
                <div>
                <div className='flex justify-around bg-[#787EF1]'>
                    <div className='flex  items-center  justify-center '>
                        <img src={Facebook} alt='facebook' />
                        <img src={SquareMsg} alt='SquareMsg' />
                        <img src={Plus} alt='Plus' />
                    </div>
                    <div>
                        <p className='text-[56px] text-center text-white font-thin'>What are you waiting for?</p>
                        <p className='text-[56px] text-center text-white'>Start Streaming Now!</p>
                    </div>
                    <div className='flex   items-center  justify-center '>
                        <img src={Tiktok} alt='Tiktok' />
                        <img src={Twitter} alt='Twitter' />
                        <img src={Instgram} alt='Instgram' />
                       
                    </div>
                </div>
                <div className='bg-[#787EF1] flex justify-center py-16'>
                    <button className='w-[473px] h-[67.997px] flex justify-center items-center  rounded-[80px] bg-white text-[#2540D9] text-[20px] font-bold'> <IoIosVideocam className='text-[30px] mr-2' />Start or Join Stream</button>
                </div>
                </div>
            </div>
        </Layout>
    )
}

export default Home
