import React from "react";
import LoginBackground from "../../../assets/backgroundPhotoWithHeadphone.png";
import Logo from "../../..//assets/logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { countryList } from "../data/Data";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
function Signup() {
  const countryNameList = () => {
    return countryList.map((item, index) => (
      <MenuItem key={index}>{item}</MenuItem>
    ));
  };

  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div className="h-screen relative bg-[#6964BA] flex justify-center">
      <img className="w-[100%] h-[100%] opacity-100" src={LoginBackground} />
      <div>
        <img
          className="absolute top-[50%] left-48 w-[427.06px] h-[133.71px]"
          src={Logo}
          alt="logo"
        />
      </div>
      <div className="w-[412.98px] h-[519.75px] bg-white absolute top-[10%] right-20 rounded-xl px-7 py-12">
        <div className="space-x-3">
          <input
            className="w-[172.35px] h-[51.91px] border rounded-full pl-5 focus:outline-none border-[#C0C0C0]"
            type="text"
            placeholder="First name"
          />
          <input
            className="w-[172.35px] h-[51.91px] border rounded-full pl-5 focus:outline-none border-[#C0C0C0]"
            type="text"
            placeholder="Last names"
          />
        </div>
        <div className="space-x-3 mt-5">
          <input
            className="w-[172.35px] h-[51.91px] border rounded-full px-5 focus:outline-none border-[#C0C0C0]"
            type="date"
          />
          <FormControl  sx={{  minWidth: 170 }}>
          <InputLabel id="demo-simple-select-helper-label">Select Gender</InputLabel>
          <Select
            style={{ borderRadius: "50px" }}
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            
            label=""
            
          >
            <MenuItem value="">
              <em>Select</em>
            </MenuItem>
            <MenuItem >Male</MenuItem>
            <MenuItem >Female</MenuItem>
          </Select>
        </FormControl>
        </div>
     
        <div className=" mt-5">
          <FormControl fullWidth >
            <InputLabel id="demo-simple-select-helper-label">Select your country</InputLabel>
            <Select
              style={{ borderRadius: "50px" }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={age}
              label="Select your country"
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {countryNameList()}
            </Select>
          </FormControl>
        </div>
        <div className=" mt-3">
        <TextField
        fullWidth
        InputProps={{
          style: {
            borderRadius: '50px'
          }
        }}
        
     
        type="email"
 
        label="Enter Email"
        
        required
      />
      </div>
        <div className="flex justify-center my-2">
          <Checkbox />
          <label className="text-[14px] text-[#111] font-bold mt-2">
            I agree to the Terms and Conditions.
          </label>
        </div>
        <div>
      <Link to="/verificationPage">
      <button className="w-[351.91px] h-[61.209px] rounded-[80px] font-bold text-center text-white text-[20px] bg-[#2540D9]">
      Receive Verification Mail
    </button>
      </Link>
        </div>
        <div className="text-center mt-3">
          <p className="text-[16px] font-semibold">
            Have an account?{" "}
            <Link to={"/login"}>
              <button className="text-[#2540D9] font-bold underline">
                Login
              </button>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Signup;
