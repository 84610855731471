import React from "react";
import backgroudImg from "../../../assets/login-backgrnd-img.png";
import Layout from "../../components/layout/Layout";
import profilePhoto from "../../../assets/profilePick.png";

import { GoGraph } from "react-icons/go";
import { IoIosArrowForward } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { GrStorage } from "react-icons/gr";
function Profile() {
  const svgRCT = () => {
    return (
      <div>
        <svg
          width="294"
          height="229"
          viewBox="0 0 294 229"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1385_15)">
            <rect
              x="16"
              y="12.4355"
              width="261.309"
              height="195.659"
              rx="16"
              fill="#787EF1"
            />
          </g>
          <path
            d="M16 169.75C124.343 137.923 158.104 -0.057972 277.309 43.7065L277.308 192.095C277.308 200.932 270.145 208.095 261.308 208.095H32C23.1634 208.095 16 200.932 16 192.095V169.75Z"
            fill="url(#paint0_linear_1385_15)"
            stroke="url(#paint1_linear_1385_15)"
          />
          <defs>
            <filter
              id="filter0_d_1385_15"
              x="0"
              y="0.435547"
              width="293.309"
              height="227.659"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="8" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1385_15"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1385_15"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1385_15"
              x1="146.654"
              y1="21.4295"
              x2="146.654"
              y2="208.095"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1385_15"
              x1="146.654"
              y1="91.1406"
              x2="146.654"
              y2="208.095"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    );
  };
  return (
    <Layout>
      <div className="relative  bg-blue-300 -mt-20">
        <img
          className="w-full opacity-20"
          src={backgroudImg}
          alt="Background"
        />
        <div className="bg-white w-full h-[610px] absolute top-72 right-0">
          <div className="flex justify-around">
            <div>
              <div className="w-72  -mt-20  shadow-xl ">
                <div className="items-center bg-[#787EF1] rounded-t-[32px]">
                  <img
                    className="h-[115px] w-[100px] mx-auto pt-5 "
                    src={profilePhoto}
                    alt="profile"
                  />
                  <p className="text-[24px] text-white font-semibold text-center">
                    User name
                  </p>
                  <p className="text-[16px] text-center text-white pb-3">
                    username@email.com
                  </p>
                </div>
                <div className="h-[276px] rounded-[32px]">
                <div className="flex justify-between mx-8 text-[#2540D9] texzt-[20px] font-bold mt-10">
                <GoGraph className="text-[20px] font-bold" />
                <p>Analytics</p>
                <IoIosArrowForward className="text-[20px] font-bold" />
                </div>
                <div className="flex justify-between mx-8 text-[#2540D9] texzt-[20px] font-bold mt-10">
                <FaUser  className="text-[20px] font-bold" />
                <p>My Profile</p>
                <IoIosArrowForward className="text-[20px] font-bold" />
                </div>
                <div className="flex justify-between mx-8 text-[#2540D9] texzt-[20px] font-bold mt-10">
                <GrStorage  className="text-[20px] font-bold" />
                <p>Subscription</p>
                <IoIosArrowForward className="text-[20px] font-bold" />
                </div>
                </div>
              </div>
            </div>

            <div className=" w-48 text-center -mt-20">
              <div className="text-white text-[48px] font-semibold">
                Statistics
              </div>
              <p className="text-[20px] font-semibold text-[#373737] mt-10">
                Total Viewers
              </p>
              <p className="text-[#787EF1] text-[64px] font-extrabold">5000</p>
             <div>
             {svgRCT()}
             </div>
            </div>
            <div className=" w-48 text-center mt-5">
              <p className="text-[20px] font-semibold text-[#373737]">
                Maximum Viewers
              </p>
              <p className="text-[#787EF1] text-[64px] font-extrabold">100</p>
              <div>
              {svgRCT()}
              </div>
            </div>
            <div className=" w-60 text-center -mt-16">
         
              <select className="h-[35px] w-[139px] rounded-full bg-[#373bc4] opacity-40 text-white px-1">
              <option>Last 30 days</option>
              </select>
              <p className="text-[20px] font-semibold text-[#373737] mt-10">
                Total Chats
              </p>
              <p className="text-[#787EF1] text-[64px] font-extrabold">1000</p>
              <div>
              {svgRCT()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Profile;
