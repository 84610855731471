import React from "react";
import LoginBackground from "../../../assets/backgroundPhotoWithHeadphone.png";
import Logo from "../../../assets/logo.png";
import mailBox from "../../../assets/mailBox.png";
import { Link } from "react-router-dom";
const VerificationPage = () => {
  return (
    <div className="h-screen relative bg-[#6964BA] flex justify-center">
      <img className="w-[100%] h-[100%] opacity-100" src={LoginBackground} />
      <div>
        <img
          className="absolute top-[50%] left-48 w-[427.06px] h-[133.71px]"
          src={Logo}
          alt="logo"
        />
      </div>
      <div className="w-[412.98px] h-[519.75px] bg-white absolute top-[10%] right-20 rounded-xl px-7 pt-5">
        <img className="mx-auto" src={mailBox} alt="mainbox" />
        <p className="text-[20px] font-bold text-center">Verification Mail Sent.</p>
        <p className="text-[20px] text-center text-sm font-semibold">Please check your inbox.</p>
       <Link to={"/profile"}> <p className="text-center bg- bg-yellow-300 w-24 h-8 rounded text-sm flex justify-center items-center mx-auto mt-5  font-semibold">View Profile</p></Link>
      </div>    
    </div>
  );
};

export default VerificationPage;
