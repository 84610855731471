import React, { Fragment, useState } from "react";
import Layout from "../../components/layout/Layout";
import { TiEdit } from "react-icons/ti";
import { GoShareAndroid } from "react-icons/go";
import facebook from "../../../assets/facbooklogo.png";
import image13 from "../../../assets/image 13.png";
import Youtub from "../../../assets/youtub.png";
import Tiktok from "../../../assets/tiktok.png";
import twitter from "../../../assets/twitter.png";
import linkdin from "../../../assets/linkdin.png";
import instgram from "../../../assets/instgram.png";
import plus from "../../../assets/plus.png";
import { IoIosMic, IoIosMicOff } from "react-icons/io";
import { FaVideo, FaVideoSlash } from "react-icons/fa6";
import { Link } from "react-router-dom";
import VideoAndMick from "./VideoAndMick";
import { Menu, Transition } from "@headlessui/react";
import { MdDeleteForever } from "react-icons/md";
import axios from "axios";

function Loby() {
  const [mute, setMute] = useState(true);
  const [videoStatus, setVideoStatus] = useState(true);
  const meetingId = useState(localStorage.getItem("meetingId"));
  const userName = useState(localStorage.getItem("userName"));
  const email = useState(localStorage.getItem("email"));
  const [socialMedia, setSocialMedia] = useState([]);
  const [offerData, setOfferData] = useState(null);
  let iceCandidateHandled = false;
  const getAllSocialMediaList = (data) => {
    setSocialMedia((prevSocialMedia) => [...prevSocialMedia, data]);
    console.log(socialMedia);
  };

  const getExistingSteam = () => {
    axios
      .get(`http://192.168.1.10:9000/api/v1/stream`)
      .then((res) => {
        setOfferData(res.result.offer);
      })
      .catch((err) => console.log(err));
  };

  if (localStorage.getItem("email")) {
    const localConnection = new RTCPeerConnection();
    localConnection.onicecandidate = (e) => {
      if (!iceCandidateHandled) {
        createSteam(JSON.stringify(localConnection.localDescription));
        iceCandidateHandled = true;
      }
    };
    const sendChannel = localConnection.createDataChannel("sendChannel");
    sendChannel.onmessage = (e) => console.log("messsage received!!!" + e.data);
    sendChannel.onopen = (e) => console.log("open!!!!");
    sendChannel.onclose = (e) => console.log("closed!!!!!!");
    localConnection
      .createOffer()
      .then((o) => localConnection.setLocalDescription(o));
  } else {
    getExistingSteam();
    if (offerData != null) {
      const connection = new RTCPeerConnection();
      connection.onicecandidate = () => {
        console.log("NEW ice candidate!! on connection reprinting SDP ");
        console.log(JSON.stringify(connection.localDescription));
      };
      connection.ondatachannel = (e) => {
        const receiveChannel = e.channel;
        receiveChannel.onmessage = (e) =>
          console.log("message received!!!" + e.data);
        receiveChannel.onopen = () => console.log("open!!!!");
        receiveChannel.onclose = () => console.log("closed!!!!!!");
        connection.channel = receiveChannel;
      };
      connection
        .setRemoteDescription(offerData)
        .then(() => console.log("offer set"));
      connection.createAnswer().then((answer) => {
        connection.setLocalDescription(answer);
        console.log(JSON.stringify(connection.localDescription));
        createParticipant(JSON.stringify(connection.localDescription));
      });
    }
  }

  const createSteam = (offer) => {
    axios
      .post(`http://192.168.1.10:9000/api/v1/stream`, {
        host_name: "ola",
        offer: offer,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  const createParticipant = (answer) => {
    axios
      .post(`http://192.168.1.10:9000/api/v1/participant`, {
        answer: answer,
        name: userName,
        host: meetingId,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => console.log(err));
  };

  return (
    <Layout>
      <div className="h-screen  bg-white space-x-10 flex p-[72px] mb-7">
        <div>
          <div className="flex space-x-10 justify-between">
            <div>
              <p className="text-xm text-gray-500">Meeting Host Name: {meetingId}</p>
              <div className="flex space-x-5">
                <p className="font-bold text-[32px] text-black ">
                  My First Song
                </p>
                <TiEdit className="font-bold text-[32px] text-black mt-3" />
              </div>
            </div>
            <GoShareAndroid className="font-bold text-[30px] mt-5 text-[#2540D9]" />
          </div>
          <div className=" w-[750px] h-[350px] mt-5 relative">
            <VideoAndMick mute={mute} />

            <div>
              {mute ? (
                <IoIosMic
                  onClick={() => setMute(!mute)}
                  className="text-[35px] bg-[#CCD3FA] p-1 rounded-full absolute -bottom-20 cursor-pointer left-5"
                />
              ) : (
                <IoIosMicOff
                  onClick={() => setMute(!mute)}
                  className="text-[35px] bg-[#CCD3FA] p-1 rounded-full absolute -bottom-20 cursor-pointer left-5"
                />
              )}
            </div>
            <div className="absolute right-44 -bottom-10">
              {videoStatus ? (
                <FaVideo
                  onClick={() => setVideoStatus(!videoStatus)}
                  className="text-[35px] bg-[#CCD3FA] p-2 rounded-full absolute  cursor-pointer "
                />
              ) : (
                <FaVideoSlash
                  onClick={() => setVideoStatus(!videoStatus)}
                  className="text-[35px] bg-[#CCD3FA] p-2 rounded-full absolute  cursor-pointer "
                />
              )}
            </div>
          </div>
        </div>
        <Menu as="div" className="relative inline-block text-left">
          <div className="">
            <p className="text-[32px] text-[#111] font-bold">
              Ready to start your stream?
            </p>
            <Link to="/meeting">
              <button className="text-[#fff] w-[420px] rounded-full bg-[#2540D9] h-12 mt-7">
                {email ? "Create stream" : "Start stream"}
              </button>
            </Link>
            <p className="text-xs text-center mt-5">
              Select platforms to start stream broadcasting-
            </p>
            <div className="flex space-x-3 mt-5">
              <Menu.Button>
                {" "}
                <div className="w-[100px] h-[40px] bg-[#DFE4FF]  rounded-[80px] flex justify-center items-center cursor-pointer">
                  {" "}
                  <img className="w-12 h-12" src={facebook} alt="facebook" />
                </div>{" "}
              </Menu.Button>
              <div
                onClick={() => getAllSocialMediaList("Twitch")}
                className="w-[100px] h-[40px] bg-[#DFE4FF]  rounded-[80px] flex justify-center items-center cursor-pointer"
              >
                {" "}
                <img className="w-12 h-12" src={image13} alt="img" />
              </div>
              <div
                onClick={() => getAllSocialMediaList("YouTub")}
                className="w-[100px] h-[40px] bg-[#DFE4FF]  rounded-[80px] flex justify-center items-center cursor-pointer"
              >
                {" "}
                <img className="w-12 h-12" src={Youtub} alt="img" />
              </div>
            </div>
            <div className="flex space-x-3 mt-5">
              <div
                onClick={() => getAllSocialMediaList("Tiktok")}
                className="w-[100px] h-[40px] bg-[#DFE4FF]  rounded-[80px] flex justify-center items-center cursor-pointer"
              >
                {" "}
                <img className="w-12 h-12" src={Tiktok} alt="facebook" />
              </div>
              <div
                onClick={() => getAllSocialMediaList("twitter")}
                className="w-[100px] h-[40px] bg-[#DFE4FF]  rounded-[80px] flex justify-center items-center cursor-pointer"
              >
                {" "}
                <img className="w-12 h-12" src={twitter} alt="img" />
              </div>
              <div
                onClick={() => getAllSocialMediaList("Linkedin")}
                className="w-[100px] h-[40px] bg-[#DFE4FF]  rounded-[80px] flex justify-center items-center cursor-pointer"
              >
                {" "}
                <img src={linkdin} alt="img" />
              </div>
            </div>

            <div className="flex space-x-3 items-center ml-14 mt-5">
              <div
                onClick={() => getAllSocialMediaList("Instgram")}
                className="w-[100px] h-[40px] bg-[#DFE4FF]  rounded-[80px] flex justify-center items-center cursor-pointer"
              >
                {" "}
                <img className="w-12 h-12" src={instgram} alt="facebook" />
              </div>
              <div
                onClick={() => getAllSocialMediaList("trovo")}
                className="w-[100px] h-[40px] bg-[#DFE4FF]  rounded-[80px] flex justify-center items-center cursor-pointer"
              >
                {" "}
                <img className="w-12 h-12" src={plus} alt="img" />
              </div>
            </div>
            <div className="bg-gray-100 mt-5 p-3 rounded w-36">
              {socialMedia.map((item) => {
                return (
                  <ul className="flex justify-between bg-green-200 my-1 p-1">
                    <li className="  text-sm  text-center rounded">{item}</li>
                    <MdDeleteForever />
                  </ul>
                );
              })}
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute p-2 left-0 top-0 mt-4 w-full h-auto py-5 origin-top-right divide-y divide-gray-100 rounded-md bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="h-72 w-72"></div>
              </Menu.Items>
            </Transition>
          </div>
        </Menu>
      </div>
    </Layout>
  );
}

export default Loby;
