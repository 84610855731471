import Home from './ui/pages/home/Home'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './ui/pages/login/Login';
import Loby from './ui/pages/loby/Loby';
import Meeting from './ui/pages/meeting/Meeting';
import VideoHome from './ui/pages/home/VideoHome';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import ScrollToTop from './ui/components/scrollToTop/ScrollToTop';
import Signup from './ui/pages/signup/Signup';
import Profile from './ui/pages/profile/Profile';
import PaymentSuccessful from './ui/pages/paymentSuccessful/PaymentSuccessful';
import VerificationPage from './ui/pages/verificationPage/VerificationPage';



function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route path='/' element={<VideoHome />} />
          <Route path='/login' element={<Login />} />
          <Route path='/signup' element={<Signup/>}/>
          <Route path='/loby' element={<Loby/>}/>
          <Route path='/meeting' element={<Meeting/>}/>
          <Route path='/profile' element={<Profile/>}/>
          <Route path='/paymentSuccessful' element={<PaymentSuccessful/>}/>
          <Route path="/verificationPage" element={<VerificationPage/>}/>
          </Routes>
          <NotificationContainer />
          
          </BrowserRouter>
          
    </div>
  );
}

export default App;
