import React, { useState } from "react";
import LoginBackground from "../../../assets/login-backgrnd-img.png";
import Logo from "../../..//assets/logo.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const changeHandler = ({ target }) => {
    setFormData((prevData) => ({
      ...prevData,
      [target.name]: target.value,
    }));
  };

  const loginHandler = () => {
    const email = formData.email;
    const password = formData.password;

    if (
      formData.email == "srimanti@wc.com" &&
      formData.password == "Welcome@123"
    ) {
      localStorage.setItem("email", email);
      navigate("/loby");
      NotificationManager.success("login successful");
    } else if (
      formData.email == "ola" &&
      formData.password == "123456"
    ) {
      localStorage.setItem("email", email);
      navigate("/loby");
      NotificationManager.success("login successful");
    } else {
      NotificationManager.error("login failed");
      setFormData({
        email: "",
        password: "",
      });
    }
  };
  return (
    <div className="h-screen relative bg-blue-300 flex justify-center">
      <img className="w-[100%] h-[100%] opacity-20" src={LoginBackground} />
      <div className=" absolute top-10 ">
        <div className="flex flex-col space-y-5">
          <img
            className=" w-[178.105px] h-[55.763px] ml-20 text-center "
            src={Logo}
            alt="logo"
          />
          <p className="w-[363.542px] text-[#FFF] text-center text-[32px] font-bold ">
            Log into Account
          </p>
          <input
            value={formData.email}
            onChange={changeHandler}
            name="email"
            type="text"
            className="opacity-40 focus:outline-none rounded-full p-2 border border-red-300 text-black font-bold h-12 w-[351.91px]"
            placeholder="Username"
          />
          <input
            value={formData.password}
            onChange={changeHandler}
            name="password"
            type="password"
            className="opacity-40 focus:outline-none rounded-full p-2 border border-red-300 text-black font-bold h-12 w-[351.91px]"
            placeholder="Username"
          />
          <button
            onClick={loginHandler}
            className="h-12 w-[351.91px] bg-white text-[#2540D9] rounded-full shadow-xl font-bold text-[20px]"
          >
            Login
          </button>
        </div>
        <div className="mt-3 flex justify-center">
        <h className="text-[16px] text-white font-semibold">Don’t have an account? <Link to={'/signup'}><button className="font-bold underline">Create account</button></Link></h>

        </div>
      </div>
    </div>
  );
};

export default Login;
