import React from 'react'

const Net = () => {
  return (
    <div>
      <svg width="792" height="1275" viewBox="0 0 792 1275" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.08">
<path d="M106.749 691.198C120.061 690.119 129.977 678.453 128.899 665.141C127.82 651.829 116.154 641.912 102.842 642.991C89.5297 644.07 79.6129 655.736 80.6918 669.048C81.7708 682.36 93.4368 692.277 106.749 691.198Z" stroke="url(#paint0_linear_785_333)" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M135.908 689.642C123.885 706.225 98.8728 709.265 82.29 698.21C64.6018 686.464 62.5289 660.07 73.7223 644.592C84.9156 629.115 110.757 622.897 127.34 636.025C142.955 648.324 147.93 673.198 135.908 689.642Z" stroke="url(#paint1_linear_785_333)" stroke-width="5.8659" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M107.782 719.678C136.814 718.037 159.018 693.172 157.377 664.141C155.736 635.109 130.871 612.905 101.84 614.546C72.8082 616.187 50.6039 641.052 52.245 670.084C53.886 699.115 78.7509 721.319 107.782 719.678Z" stroke="url(#paint2_linear_785_333)" stroke-width="5.7317" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M165.286 695.986C181.236 662.584 167.087 622.576 133.685 606.626C100.282 590.676 60.2744 604.824 44.3246 638.227C28.3747 671.629 42.5229 711.637 75.9254 727.587C109.328 743.537 149.336 729.389 165.286 695.986Z" stroke="url(#paint3_linear_785_333)" stroke-width="5.5976" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M170.593 714.793C145.028 750.169 92.516 757.355 57.1395 732.896C20.3811 707.469 14.1626 653.713 39.0367 619.442C63.9108 585.033 116.976 574.254 152.49 601.339C186.761 627.457 196.158 679.416 170.593 714.793Z" stroke="url(#paint4_linear_785_333)" stroke-width="5.4634" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M182.201 723.222C152.214 764.679 90.0283 775.182 48.7096 744.504C8.08189 714.378 -2.42051 652.193 27.4284 611.013C57.1392 569.97 119.739 560.435 160.92 589.731C202.791 619.58 212.188 681.766 182.201 723.222Z" stroke="url(#paint5_linear_785_333)" stroke-width="5.3293" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M193.671 731.652C158.156 779.465 88.7848 791.212 40.2803 756.111C-8.22422 721.011 -19.2794 651.087 15.8207 602.721C51.0591 554.078 120.983 544.681 169.211 578.261C217.577 611.98 229.6 683.285 193.671 731.652Z" stroke="url(#paint6_linear_785_333)" stroke-width="5.1951" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M205.279 740.358C164.927 794.252 87.2648 807.656 31.9889 767.72C-22.8723 728.059 -35.3094 649.153 4.35104 594.43C45.2551 538.048 120.707 532.521 177.226 567.483C235.266 603.274 245.906 686.049 205.279 740.358Z" stroke="url(#paint7_linear_785_333)" stroke-width="5.061" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M216.887 748.649C171.975 807.932 84.7774 822.442 23.5594 779.189C-38.0731 735.659 -50.5102 645.56 -7.25685 585.862C38.3457 522.847 120.983 520.36 184.688 557.118C215.228 574.807 236.233 594.015 241.623 630.774C247.427 669.328 240.655 717.28 216.887 748.649Z" stroke="url(#paint8_linear_785_333)" stroke-width="4.9268" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M228.356 756.664C179.714 821.89 82.1518 837.92 15.1299 790.659C-53.1358 742.431 -66.5401 643.487 -18.8648 577.432C33.2327 505.297 119.048 513.727 191.736 547.307C227.527 563.89 249.914 577.709 255.994 620.409C262.075 664.492 255.165 720.873 228.356 756.664Z" stroke="url(#paint9_linear_785_333)" stroke-width="4.7927" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M239.964 765.094C187.176 837.92 79.5261 855.746 6.8384 802.267C-65.1583 749.34 -84.5049 641.967 -30.3346 569.141C-5.32227 535.423 32.6798 507.923 75.795 506.541C118.081 505.159 159.538 525.335 198.369 537.219C241.208 550.209 264.009 559.053 270.228 608.94C276.723 660.899 271.471 721.564 239.964 765.094Z" stroke="url(#paint10_linear_785_333)" stroke-width="4.6585" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M251.572 773.523C194.223 852.706 77.4533 871.914 -1.45294 813.875C-79.8064 756.388 -99.0148 639.618 -41.8043 560.85C-15.9629 525.197 27.2905 493.69 73.0312 493.551C119.325 493.413 160.505 519.255 205.14 526.855C223.381 529.895 246.874 527.546 262.351 539.154C279.21 551.729 282.388 577.985 284.738 597.055C291.647 655.095 286.949 724.604 251.572 773.523Z" stroke="url(#paint11_linear_785_333)" stroke-width="4.5244" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M263.042 781.815C200.58 868.045 76.2096 887.806 -10.0207 825.344C-96.1128 763.021 -115.045 638.374 -53.5504 552.42C-26.0507 514.003 20.9337 479.041 70.1292 480.009C119.463 480.976 162.854 512.345 211.635 516.214C229.324 517.596 254.474 512.483 271.057 519.946C293.858 530.172 296.484 563.199 298.833 584.618C306.157 649.429 302.702 727.23 263.042 781.815Z" stroke="url(#paint12_linear_785_333)" stroke-width="4.3902" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M274.65 790.244C207.628 882.693 74.1367 903.974 -18.3121 836.952C-110.761 769.93 -131.213 636.301 -65.0201 543.991C-36.1385 503.639 15.268 463.979 67.5036 466.743C120.016 469.506 165.342 504.745 218.545 505.712C238.03 506.127 268.155 496.315 286.949 504.883C309.75 515.247 311.132 551.453 313.343 572.458C320.805 644.178 318.456 729.717 274.65 790.244Z" stroke="url(#paint13_linear_785_333)" stroke-width="4.2561" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M286.258 798.536C214.675 897.341 72.202 920.004 -26.6035 848.422C-125.409 776.84 -148.072 634.366 -76.4899 535.561C-45.5355 492.86 9.32578 448.778 65.1543 452.785C121.397 456.793 167.691 495.901 225.731 495.071C247.565 494.795 276.17 483.049 297.727 488.853C324.674 496.177 325.78 538.878 327.853 560.711C335.177 639.341 334.209 732.343 286.258 798.536Z" stroke="url(#paint14_linear_785_333)" stroke-width="4.122" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M297.727 806.965C221.585 912.127 69.991 936.311 -35.1712 860.03C-140.333 783.888 -164.517 632.294 -88.236 527.131C-55.8996 482.496 3.9364 433.301 62.667 438.552C122.779 443.941 170.04 486.365 232.916 484.845C255.441 484.293 291.785 468.263 313.205 475.863C339.737 485.26 339.737 527.822 341.81 550.762C349.687 635.196 349.41 735.659 297.727 806.965Z" stroke="url(#paint15_linear_785_333)" stroke-width="3.9878" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M309.335 815.395C228.495 926.914 68.0565 952.479 -43.4625 871.638C-154.981 790.797 -180.546 630.359 -99.7056 518.84C-65.711 471.856 -1.45285 418.791 60.7324 424.18C89.3377 426.668 115.041 442.145 141.021 452.924C173.081 466.19 205.831 475.034 240.793 474.758C266.773 474.481 296.898 460.386 322.187 463.841C354.247 468.263 353.971 518.425 356.043 542.609C364.197 633.123 364.749 738.838 309.335 815.395Z" stroke="url(#paint16_linear_785_333)" stroke-width="3.8537" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M320.943 823.686C235.542 940.594 65.9836 968.509 -51.7539 883.108C-169.491 797.845 -196.576 628.148 -111.175 510.41C-75.5225 461.215 -6.42768 404.143 58.936 409.532C87.403 411.881 112.001 426.529 137.428 437.723C172.804 453.338 210.53 463.564 249.223 464.393C276.999 464.946 307.815 451.265 334.762 454.582C368.619 458.728 368.342 511.792 370.553 537.219C378.568 632.017 379.259 743.813 320.943 823.686Z" stroke="url(#paint17_linear_785_333)" stroke-width="3.7195" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M332.413 832.116C243.004 955.519 63.358 983.157 -60.1835 894.715C-184.83 805.445 -211.777 625.246 -122.783 502.119C-84.2284 448.778 -12.9226 391.706 56.8631 395.299C88.5085 396.957 115.87 410.776 144.061 423.904C179.99 440.763 218.268 451.127 257.929 454.029C287.225 456.24 316.659 445.461 345.265 447.811C382.576 450.713 382.576 509.581 385.063 536.528C393.493 634.919 392.387 749.34 332.413 832.116Z" stroke="url(#paint18_linear_785_333)" stroke-width="3.5854" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M344.021 840.545C250.052 970.305 61.2851 999.325 -68.6131 906.323C-199.617 812.493 -227.946 622.758 -134.391 493.689C-92.6581 436.203 -19.4176 379.959 55.0666 380.098C88.6466 380.236 119.325 391.429 149.035 406.077C186.07 424.318 226.007 437.032 266.911 442.836C296.898 447.12 328.544 438.137 357.563 442.974C395.98 449.469 397.362 508.061 399.988 537.772C408.97 639.065 405.377 755.697 344.021 840.545Z" stroke="url(#paint19_linear_785_333)" stroke-width="3.4512" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M355.491 848.837C256.823 985.091 59.3505 1015.36 -77.0426 917.793C-214.679 819.402 -243.976 620.548 -145.999 485.26C-100.949 423.075 -25.6361 368.904 53.5465 364.759C132.867 360.475 201.133 412.987 276.584 431.09C307.124 438.414 342.086 433.024 371.382 441.868C409.799 453.615 412.563 508.89 415.603 541.641C424.862 644.592 417.814 762.883 355.491 848.837Z" stroke="url(#paint20_linear_785_333)" stroke-width="3.3171" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M367.098 857.266C264.838 999.325 55.8956 1031.8 -85.3341 929.539C-226.426 827.141 -261.387 618.475 -157.469 476.969C-108.135 409.808 -31.7165 358.54 52.1645 349.143C139.915 339.47 208.181 390.738 286.534 417.823C317.212 428.326 353.279 429.846 383.128 442.145C422.651 458.589 428.04 510.272 431.357 547.307C440.753 652.055 429.975 769.93 367.098 857.266Z" stroke="url(#paint21_linear_785_333)" stroke-width="3.1829" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M378.706 865.558C271.057 1013.97 54.7902 1049.35 -93.6254 941.009C-241.212 833.221 -276.588 616.955 -169.077 468.677C-116.979 396.819 -36.9676 348.314 50.6445 333.666C91.2723 326.895 131.071 325.098 169.626 341.266C213.294 359.507 251.296 388.251 296.069 404.281C327.024 415.474 362.538 427.773 391.973 442.698C433.844 463.841 443.517 511.931 447.248 554.631C456.507 659.931 442.135 778.084 378.706 865.558Z" stroke="url(#paint22_linear_785_333)" stroke-width="3.0488" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M390.314 873.987C278.104 1028.76 52.7173 1065.52 -102.055 952.617C-255.998 840.269 -292.757 614.882 -180.685 460.248C-124.856 383.276 -42.9099 338.226 48.9862 318.189C91.4104 308.93 135.907 300.5 177.502 317.36C223.105 335.877 259.034 372.635 305.051 391.153C338.631 404.695 374.561 425.424 404.824 446.014C444.899 473.237 459.271 515.938 463.278 562.508C472.537 667.946 453.329 786.928 390.314 873.987Z" stroke="url(#paint23_linear_785_333)" stroke-width="2.9146" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M401.784 882.417C285.014 1043.41 50.5063 1081.69 -110.484 964.225C-270.646 847.178 -308.925 612.809 -192.293 451.818C-132.871 369.872 -48.5756 328 47.3279 302.988C91.4104 291.518 141.02 275.488 185.794 292.762C207.213 301.053 223.52 317.636 240.932 332.146C263.456 351.078 286.672 367.108 313.619 378.992C350.101 395.022 378.43 420.311 409.661 444.77C451.947 477.936 474.748 517.044 479.585 570.938C488.982 675.685 464.108 796.325 401.784 882.417Z" stroke="url(#paint24_linear_785_333)" stroke-width="2.7805" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M413.392 890.708C292.062 1058.06 48.5717 1097.72 -118.776 975.695C-285.294 854.226 -325.093 610.736 -203.762 443.527C-140.886 356.744 -54.2413 317.636 45.5316 288.34C90.9959 274.935 146.548 250.476 193.809 268.993C217.578 278.252 233.608 299.533 251.296 316.945C272.301 337.673 294.549 355.224 321.358 367.799C346.232 379.407 367.099 390.324 385.063 411.467C395.704 423.904 406.344 435.373 418.505 446.567C459.409 484.154 490.916 521.466 495.891 579.091C505.012 683.147 474.886 805.86 413.392 890.708Z" stroke="url(#paint25_linear_785_333)" stroke-width="2.6463" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M425 899.138C298.556 1073.53 47.3279 1113.88 -127.205 987.303C-301.601 860.859 -340.985 609.492 -215.37 435.097C-184.692 392.535 -144.894 355.915 -99.8439 328.691C-54.3796 301.192 -6.56599 289.445 43.4586 273.692C88.5084 259.458 150.003 226.431 198.369 244.119C225.316 253.931 241.346 281.016 259.311 301.882C279.21 324.822 300.768 344.168 328.129 357.435C351.898 369.043 374.008 378.439 390.452 400.273C402.06 415.612 410.49 431.642 424.033 445.6C464.798 487.886 505.841 524.229 511.645 586.277C521.456 689.504 485.665 815.533 425 899.138Z" stroke="url(#paint26_linear_785_333)" stroke-width="2.5122" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M436.47 907.429C305.466 1088.04 45.1169 1129.91 -135.635 998.772C-316.249 867.768 -358.12 607.419 -226.978 426.668C-194.918 382.447 -154.014 344.721 -107.306 316.392C-60.1836 287.925 -10.4353 276.317 41.2476 259.596C88.9229 244.119 160.367 200.037 211.359 224.911C234.437 236.104 248.808 264.157 263.318 283.918C282.941 310.727 304.499 333.39 334.624 348.314C358.807 360.198 382.023 370.148 397.915 393.502C409.384 410.499 415.603 428.049 429.422 443.803C469.773 489.682 521.732 526.579 527.536 592.08C532.373 646.251 518.14 700.974 505.426 753.209C491.884 809.038 470.326 860.859 436.47 907.429Z" stroke="url(#paint27_linear_785_333)" stroke-width="2.378" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M448.078 915.859C312.375 1102.83 43.1822 1146.08 -143.926 1010.38C-330.897 874.816 -374.15 605.485 -238.448 418.376C-205.973 373.465 -164.655 335.048 -117.532 306.028C-67.784 275.35 -15.6865 263.604 38.8983 245.916C88.0938 230.024 161.196 180.967 213.985 204.597C240.517 216.481 254.75 249.37 269.675 272.31C288.33 300.777 309.335 325.789 340.29 340.161C363.367 350.94 388.103 360.751 402.751 383C415.741 402.623 420.025 424.18 435.502 442.836C475.577 491.202 536.381 527.961 542.737 595.812C547.85 650.811 531.406 706.64 518.692 759.566C505.15 815.948 482.21 868.736 448.078 915.859Z" stroke="url(#paint28_linear_785_333)" stroke-width="2.2439" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M459.685 924.288C319.561 1117.62 41.1094 1162.25 -152.356 1021.99C-345.683 881.864 -390.318 603.412 -250.056 409.947C-215.094 361.718 -170.459 320.676 -119.191 290.274C-69.5805 260.978 -18.0357 248.956 36.4109 232.511C86.8501 217.31 164.789 161.482 218.959 187.185C245.906 199.899 258.62 232.788 272.439 256.971C290.542 288.478 312.099 316.945 345.956 332.284C370.139 343.201 396.947 349.143 410.49 374.294C420.854 393.64 422.927 414.921 435.917 433.439C474.748 488.438 550.476 523.538 557.662 596.641C563.051 652.055 544.948 709.265 532.649 762.883C519.107 820.922 494.786 875.922 459.685 924.288Z" stroke="url(#paint29_linear_785_333)" stroke-width="2.1098" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M471.155 932.579C326.471 1132.26 38.8983 1178.28 -160.786 1033.46C-360.331 888.773 -406.348 601.339 -261.664 401.655C-225.596 351.907 -179.441 309.345 -126.515 278.114C-75.3844 247.85 -22.1815 234.999 34.1998 218.969C88.0938 203.63 163.407 146.005 221.447 172.952C250.19 186.356 262.213 220.074 276.308 246.192C293.72 278.805 315.83 311.832 351.483 323.993C374.975 332.146 403.995 335.324 416.847 360.06C429.56 384.382 426.935 409.117 443.932 432.748C483.592 487.471 563.328 522.156 572.725 594.844C580.049 651.364 558.906 711.476 546.468 766.199C532.926 826.035 507.361 882.831 471.155 932.579Z" stroke="url(#paint30_linear_785_333)" stroke-width="1.9756" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M482.763 941.009C333.518 1147.05 36.9637 1194.45 -169.077 1045.07C-375.117 895.821 -422.516 599.266 -273.134 393.226C-236.513 342.787 -190.082 299.948 -136.741 267.611C-82.9848 234.999 -27.4326 222.009 32.1271 205.288C89.3375 189.258 162.578 128.455 223.934 160.929C288.33 195.062 282.803 295.664 358.393 314.043C381.332 319.571 412.563 319.571 425.276 344.03C437.713 367.937 430.942 396.404 445.452 420.449C483.454 484.154 577.838 513.865 589.998 592.91C598.842 649.982 572.863 713.964 560.564 769.239C546.607 831.148 519.936 889.741 482.763 941.009Z" stroke="url(#paint31_linear_785_333)" stroke-width="1.8415" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M494.371 949.439C340.566 1161.7 34.891 1210.62 -177.506 1056.67C-389.765 902.869 -438.546 597.193 -284.741 384.796C-245.772 330.902 -195.609 285.3 -138.261 251.72C-84.5048 220.212 -29.5054 207.637 29.5016 191.331C87.5412 175.439 149.865 116.847 213.294 143.932C287.225 175.439 281.836 290.965 365.855 302.159C392.802 305.752 427.487 302.435 438.128 334.219C447.387 361.857 437.852 389.08 453.191 415.751C491.607 482.22 594.006 510.41 608.516 591.666C613.767 620.962 606.719 649.706 598.428 677.62C588.755 710.371 581.154 742.569 573.554 775.734C559.32 838.196 531.959 897.479 494.371 949.439Z" stroke="url(#paint32_linear_785_333)" stroke-width="1.7073" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M505.979 957.73C347.476 1176.35 32.68 1226.65 -185.798 1068.28C-404.414 909.778 -454.715 595.121 -296.349 376.505C-256.827 322.058 -206.388 275.765 -148.625 241.355C-92.7962 208.19 -35.1711 193.542 27.0142 177.374C85.8829 162.035 146.686 96.5328 210.806 132.186C280.868 171.155 288.883 285.438 374.975 288.34C401.093 289.169 438.957 282.26 450.289 313.49C461.897 345.136 445.728 379.545 463.278 410.499C502.248 479.594 612.8 507.785 628.277 590.284C634.081 621.377 624.822 650.811 614.596 679.969C603.403 711.891 595.526 742.845 588.064 775.734C573.554 841.374 545.225 903.422 505.979 957.73Z" stroke="url(#paint33_linear_785_333)" stroke-width="1.5732" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M517.449 966.16C354.385 1190.3 30.6069 1242.82 -194.227 1079.75C-419.062 916.826 -470.883 592.91 -307.819 368.075C-268.573 313.905 -218.825 267.612 -161.891 232.65C-102.608 196.306 -41.9426 180.276 24.5266 163.417C86.2973 147.663 135.078 79.9501 203.482 119.334C275.064 160.653 293.996 277.423 385.201 272.586C409.937 271.204 452.361 260.84 464.522 291.104C478.203 325.375 457.06 365.312 473.09 399.859C489.949 436.341 528.918 463.841 559.32 488.3C592.347 514.833 640.299 544.543 649.558 589.179C656.191 621.515 643.339 653.16 631.179 682.457C618.327 713.687 610.036 743.26 602.85 776.149C587.925 844.415 558.491 909.778 517.449 966.16Z" stroke="url(#paint34_linear_785_333)" stroke-width="1.439" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M529.057 974.589C361.295 1204.95 28.6724 1258.98 -202.657 1091.36C-433.848 923.874 -487.051 590.975 -319.427 359.646C-277.003 301.192 -222.694 251.443 -160.371 215.099C-101.778 180.967 -42.0806 167.148 22.1775 149.598C81.8754 133.429 124.161 65.0256 191.46 103.58C232.64 127.211 260.969 169.911 290.127 205.979C321.081 244.396 347.199 261.393 396.395 255.036C423.065 251.581 468.668 237.348 481.243 271.066C495.338 308.516 472.952 352.46 485.942 391.429C499.208 431.228 538.454 459.28 570.237 484.016C604.508 510.687 660.613 541.918 670.839 588.073C678.301 621.377 661.995 654.266 648.038 683.424C632.837 715.207 624.131 745.195 616.669 779.327C601.33 849.528 571.066 916.688 529.057 974.589Z" stroke="url(#paint35_linear_785_333)" stroke-width="1.3049" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M540.665 982.88C369.033 1219.88 26.0467 1273.49 -210.948 1102.97C-449.187 931.474 -502.114 587.935 -331.035 351.354C-289.025 293.177 -235.27 243.567 -173.775 206.532C-111.59 168.944 -48.9902 154.158 19.8282 135.641C48.2952 128.04 65.0162 110.49 87.6792 93.7691C121.121 69.0331 158.156 73.0406 191.598 96.5329C232.778 125.414 262.351 167.7 296.069 204.183C330.755 241.77 360.051 245.363 408.141 236.519C436.331 231.406 487.462 213.303 500.175 251.443C513.303 290.551 492.16 338.641 498.931 379.545C506.255 424.042 545.087 453.062 578.943 478.765C615.701 506.679 680.098 539.016 691.982 587.382C700.55 622.344 680.098 656.477 664.068 686.049C646.103 719.215 637.95 749.478 629.797 785.684C613.905 856.575 583.089 924.288 540.665 982.88Z" stroke="url(#paint36_linear_785_333)" stroke-width="1.1707" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M552.134 991.31C376.081 1234.66 23.8357 1289.52 -219.378 1114.44C-463.973 938.522 -518.144 585.862 -342.643 342.925C-298.146 281.292 -240.797 229.195 -175.295 190.64C-113.663 154.434 -50.5102 139.372 17.6172 121.545C49.4008 113.116 61.4233 92.1108 86.2974 73.7316C114.488 53.0031 147.515 60.3272 175.568 78.43C225.731 110.905 259.864 161.896 303.117 202.11C340.704 237.21 374.837 228.918 421.269 216.896C452.5 208.881 507.361 188.429 519.66 231.959C532.235 276.179 509.434 327.862 513.718 373.465C518.416 422.522 557.662 451.404 594.973 478.765C631.179 505.297 700.826 539.016 713.678 586.415C722.937 620.548 699.721 655.371 683.138 683.009C662.548 717.418 652.875 747.406 644.445 786.237C628.139 859.892 596.217 930.369 552.134 991.31Z" stroke="url(#paint37_linear_785_333)" stroke-width="1.0366" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M563.742 999.74C383.128 1250.14 21.7629 1305.69 -227.807 1126.18C-478.621 945.569 -534.312 583.789 -354.113 334.495C-309.892 273.277 -253.234 221.042 -188.562 181.934C-123.613 142.688 -57.0051 125.691 15.4062 107.312C47.1898 99.2966 55.3429 80.7792 77.7296 60.0508C109.099 31.031 148.897 50.9303 178.193 73.0406C227.389 110.075 263.042 161.896 311.132 199.76C350.654 230.853 391.42 211.783 434.259 197.826C467.01 187.185 526.155 163.969 539.559 211.368C552.963 258.767 527.813 315.84 528.366 364.206C529.057 416.856 564.433 445.6 604.646 474.619C643.201 502.395 719.205 535.837 734.13 585.309C745.323 622.482 714.783 660.346 695.99 689.09C674.432 722.117 666.555 751.689 658.126 789.553C641.543 865.143 608.93 937.002 563.742 999.74Z" stroke="url(#paint38_linear_785_333)" stroke-width="0.9024" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M575.35 1008.03C390.176 1264.79 19.8283 1321.72 -236.099 1137.51C-493.269 952.479 -550.48 581.302 -365.721 326.204C-272.028 196.858 -139.919 127.211 13.1952 93.3545C45.117 86.3068 51.3355 69.5859 70.9584 46.7846C97.3526 16.1066 136.737 35.0385 164.236 54.7996C219.236 94.3218 258.482 152.085 313.205 191.607C356.873 222.976 402.613 196.029 447.11 179.447C481.519 166.595 547.159 140.615 559.044 194.509C570.79 247.298 545.363 306.028 543.29 358.955C541.217 411.467 571.481 439.796 612.247 468.954C638.641 487.886 666.417 504.883 692.811 523.538C714.231 538.739 744.08 557.671 753.062 583.789C765.637 620.547 734.13 657.859 714.369 685.773C689.08 721.702 680.927 753.209 671.53 795.219C654.395 871.914 621.091 944.602 575.35 1008.03Z" stroke="url(#paint39_linear_785_333)" stroke-width="0.7683" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M586.82 1016.46C397.224 1279.44 17.6172 1337.89 -244.528 1149.12C-507.918 959.526 -566.51 579.229 -377.328 317.774C-282.392 186.632 -145.723 111.457 10.8459 79.2591C43.5968 72.4878 48.2953 58.2543 66.1217 33.6566C91.9632 -1.99634 133.558 23.1541 161.196 43.8826C216.887 85.4776 258.343 144.899 315.692 184.145C361.847 215.79 414.774 179.723 459.271 161.758C497.135 146.419 565.262 121.545 576.732 180.552C587.235 234.308 564.157 294.144 558.629 347.485C552.964 402.761 575.212 431.781 619.018 463.564C648.038 484.569 678.992 502.672 708.427 522.986C729.846 537.91 761.215 556.704 771.165 582.545C785.675 620.548 748.916 660.208 728.464 687.984C702.346 723.361 695.299 754.039 686.04 796.048C668.49 875.369 634.219 950.682 586.82 1016.46Z" stroke="url(#paint40_linear_785_333)" stroke-width="0.6341" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M598.428 1024.89C403.995 1294.36 15.8207 1354.06 -252.958 1160.73C-522.98 966.436 -582.54 577.018 -388.798 309.345C-292.618 176.406 -151.25 95.8419 8.63482 65.1638C44.1495 58.3925 47.1897 44.7117 65.8453 17.3502C90.4431 -18.8554 135.631 12.3754 160.782 32.2747C216.472 76.3571 262.489 146.972 325.918 180.276C372.626 204.735 429.284 161.896 471.155 144.346C510.263 128.04 576.87 106.621 591.242 164.937C605.061 221.18 581.43 284.332 573.968 339.885C566.23 397.371 584.609 427.911 631.317 461.491C660.751 482.634 692.258 500.599 722.246 520.913C744.356 535.837 776.416 554.493 787.195 580.473C803.916 620.686 762.597 661.59 740.763 690.748C713.263 727.506 708.703 760.948 698.753 804.34C680.512 883.799 645.827 958.974 598.428 1024.89Z" stroke="url(#paint41_linear_785_333)" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<linearGradient id="paint0_linear_785_333" x1="80.6061" y1="652.403" x2="128.943" y2="681.79" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint1_linear_785_333" x1="70.3995" y1="642.192" x2="139.189" y2="692.044" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint2_linear_785_333" x1="57.101" y1="636.502" x2="152.48" y2="697.725" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint3_linear_785_333" x1="42.549" y1="700.985" x2="167.045" y2="633.266" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint4_linear_785_333" x1="35.9255" y1="617.208" x2="173.663" y2="717.028" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint5_linear_785_333" x1="24.4334" y1="608.88" x2="185.154" y2="725.357" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint6_linear_785_333" x1="12.9337" y1="600.566" x2="196.681" y2="733.73" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint7_linear_785_333" x1="1.29041" y1="592.459" x2="208.144" y2="742.368" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint8_linear_785_333" x1="-10.3972" y1="584.413" x2="219.386" y2="750.94" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint9_linear_785_333" x1="-21.5057" y1="575.569" x2="231.119" y2="758.649" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint10_linear_785_333" x1="-31.6583" y1="565.392" x2="243.934" y2="765.117" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint11_linear_785_333" x1="-40.1088" y1="552.904" x2="258.441" y2="769.267" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint12_linear_785_333" x1="-49.5198" y1="541.717" x2="271.999" y2="774.726" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint13_linear_785_333" x1="-58.9169" y1="530.516" x2="285.569" y2="780.169" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint14_linear_785_333" x1="-68.6651" y1="519.806" x2="298.784" y2="786.101" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint15_linear_785_333" x1="-78.6853" y1="509.465" x2="311.731" y2="792.404" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint16_linear_785_333" x1="-89.2978" y1="499.941" x2="324.085" y2="799.525" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint17_linear_785_333" x1="-100.564" y1="491.32" x2="335.786" y2="807.549" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint18_linear_785_333" x1="-112.498" y1="483.619" x2="346.818" y2="816.491" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint19_linear_785_333" x1="-125.194" y1="476.97" x2="357.088" y2="826.486" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint20_linear_785_333" x1="-138.585" y1="471.281" x2="366.664" y2="837.442" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint21_linear_785_333" x1="-152.575" y1="466.407" x2="375.647" y2="849.217" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint22_linear_785_333" x1="-166.972" y1="462.103" x2="384.222" y2="861.56" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint23_linear_785_333" x1="-181.542" y1="458.03" x2="392.629" y2="874.138" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint24_linear_785_333" x1="-195.447" y1="453.039" x2="401.7" y2="885.8" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint25_linear_785_333" x1="-206.446" y1="444.037" x2="413.679" y2="893.449" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint26_linear_785_333" x1="-215.456" y1="432.289" x2="427.647" y2="898.354" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint27_linear_785_333" x1="-224.824" y1="421.039" x2="441.255" y2="903.755" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint28_linear_785_333" x1="-235.072" y1="411.001" x2="453.984" y2="910.369" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint29_linear_785_333" x1="-245.852" y1="401.698" x2="466.181" y2="917.718" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint30_linear_785_333" x1="-257.648" y1="393.793" x2="477.365" y2="926.467" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint31_linear_785_333" x1="-270.176" y1="386.895" x2="487.821" y2="936.224" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint32_linear_785_333" x1="-283.21" y1="380.696" x2="497.769" y2="946.681" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint33_linear_785_333" x1="-296.978" y1="375.51" x2="506.984" y2="958.151" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint34_linear_785_333" x1="-305.524" y1="363.117" x2="521.421" y2="962.415" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint35_linear_785_333" x1="-313.405" y1="349.807" x2="536.524" y2="965.761" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint36_linear_785_333" x1="-320.789" y1="335.812" x2="552.121" y2="968.421" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint37_linear_785_333" x1="-327.975" y1="321.553" x2="567.908" y2="970.811" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint38_linear_785_333" x1="-335.136" y1="307.261" x2="583.72" y2="973.168" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint39_linear_785_333" x1="-342.754" y1="293.601" x2="599.074" y2="976.155" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint40_linear_785_333" x1="-351.331" y1="281.263" x2="613.468" y2="980.466" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
<linearGradient id="paint41_linear_785_333" x1="-361.1" y1="270.569" x2="626.672" y2="986.419" gradientUnits="userSpaceOnUse">
<stop stop-color="#8900EF"/>
<stop offset="1" stop-color="#4EFEF6"/>
</linearGradient>
</defs>
</svg>

    </div>
  )
}

export default Net
