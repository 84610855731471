import React, { useRef, useState } from "react";

function Meeting({ mute }) {
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);

  let localStream;
  let remoteStream;
  let rtcPeerConnection;

  const startCall = async () => {
    try {
      localStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      localVideoRef.current.srcObject = localStream;

      // Create an RTCPeerConnection
      rtcPeerConnection = new RTCPeerConnection();

      // Add the local stream to the RTCPeerConnection
      localStream
        .getTracks()
        .forEach((track) => rtcPeerConnection.addTrack(track, localStream));

      // Set up event handlers for handling remote stream
      rtcPeerConnection.ontrack = (event) => {
        remoteStream = event.streams[0];
        remoteVideoRef.current.srcObject = remoteStream;
      };

      // Your signaling code goes here (to set up communication with the remote peer)
    } catch (error) {
      console.error("Error starting the call:", error);
      // You can add additional error handling or UI updates here
    }
  };

  const stopCall = () => {
    // Stop local media tracks
    if (localStream) {
      localStream.getTracks().forEach((track) => track.stop());
    }

    // Close the RTCPeerConnection
    if (rtcPeerConnection) {
      rtcPeerConnection.close();
    }

    // Clear video elements
    localVideoRef.current.srcObject = null;
    remoteVideoRef.current.srcObject = null;
  };

  const toggleAudio = () => {
    if (localStream) {
      localStream.getAudioTracks().forEach((track) => {
        track.enabled = !isAudioMuted;
        setIsAudioMuted(!isAudioMuted);
      });
    }
  };

  const toggleVideo = () => {
    if (localStream) {
      localStream.getVideoTracks().forEach((track) => {
        track.enabled = !isVideoMuted;
        setIsVideoMuted(!isVideoMuted);
      });
    }
  };

  return (
    <div className="flex flex-col items-center mt-8">
      <div className="flex ">
        <video
        style={{
            height:'470px'
        }}
          ref={localVideoRef}
          className="rounded-md shadow-md"
          autoPlay
          playsInline
          muted
        />
        <video
          ref={remoteVideoRef}
          className="rounded-md shadow-md ml-4"
          autoPlay
          playsInline
        />
      </div>
      <div className="mt-4 space-x-4">
       {isVideoMuted ? <button
          onClick={stopCall}
          className="bg-red-500 text-white px-4 py-2 rounded-md"
        >
          Stop stream
        </button>
        :
        <button
          onClick={startCall}
          className="bg-blue-500 text-white px-4 py-2 rounded-full"
        >
          Start stream
        </button>}

        <button
          onClick={toggleAudio}
          className={`${
            isAudioMuted ? "bg-gray-300" : "bg-green-500"
          } text-white px-4 py-2 rounded-full`}
        >
          {isAudioMuted ? "Unmute Microphone" : "Mute Microphone"}
        </button>
        <button
          onClick={toggleVideo}
          className={`${
            isVideoMuted ? "bg-gray-300" : "bg-red-500"
          } text-white px-4 py-2 rounded-full`}
        >
          {isVideoMuted ? "Unmute Video" : "Mute Video"}
        </button>
      </div>
    </div>
  );
}

export default Meeting;
