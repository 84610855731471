import BottomToTopButton from '../bottomToTopButton/BottomToTopButton'
import Footer from '../footer/Footer'
import Navbar from '../navbar/Navbar'

function Layout({ children }) {
  return (
    <div>
      <Navbar />
      <div className="">
        {children}
        <BottomToTopButton />
      </div>
    
      <Footer />
    </div>
  )
}

export default Layout